import React from 'react';
import ReactDOM from 'react-dom';
import './reset.css';
import './index.css';
import Routes from './Routes';

//Fonts
import './Fonts/Open_Sans/OpenSans-Regular.ttf';
import './Fonts/Open_Sans/OpenSans-Bold.ttf';
import './Fonts/Open_Sans/OpenSans-SemiBold.ttf'

ReactDOM.render(
    <React.StrictMode>
        <Routes />
    </React.StrictMode>,
    document.getElementById('root')
);
