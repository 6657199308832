import React, {useState} from 'react';

function Slideshow(props) {

    const [index, setIndex] = React.useState(0);
    const timeoutRef = React.useRef(null);
    const [sliding, setSliding] = React.useState(true);

    const slide = {
        transform: `translate3d(${-index* 100}%, 0, 0)`
    };

    const imgs = [props.img1, props.img2, props.img3];
    const delay = 2500;

    function resetTimeout() {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    };

    function toggleSliding(){
        setSliding((prevSlide) => prevSlide === true ? false : true);
        console.log(sliding);
        if(sliding === true){
            resetTimeout();
        }
        if(sliding === false){
            setIndex(0);
        }
    };

    React.useEffect(() => {
        resetTimeout();
        if(sliding === true){
            timeoutRef.current = setTimeout(
                () =>
                setIndex((prevIndex) =>
                    prevIndex === imgs.length - 1 ? 0 : prevIndex + 1
                ),
                delay
            );
        }
        return () => {
        resetTimeout();
        };
    }, [index, imgs.length, sliding]);

    return (
        <div className="slideshow-wrapper">
            <a href="https://kath-ldn.github.io/shopping-cart-ReactJS/" target="_blank" rel="noreferrer">
                <div className="slideshow">
                    <div
                    className="slideshowSlider"
                    style={slide}
                    >
                    {imgs.map((img, index) => (
                        <div
                        className="slide"
                        key={index}
                        style={{ backgroundImage: 'url(' + img +')' }}
                        ></div>
                    ))}
                    </div>
                </div>
            </a>
                <span className="play-pause" onClick={toggleSliding}></span>
        </div>
    );
}

export default Slideshow;

// add props after it works