import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';

const Nav = () => {

    const navStyle = {
        color: 'rgb(40, 40, 40)',
        textDecoration: 'none'
    };

    const logoStyle ={
        textDecoration: 'none'
    }

    const [menuState, setMenuState] = useState('d-none');

    function toggleBurger(){
        if(menuState === 'd-none'){
            setMenuState('hamburger-links')
        } else if(menuState === 'hamburger-links'){
            setMenuState('d-none')
        }
        console.log(menuState)
    };

    return(
        <nav>
            <Link style={logoStyle} to="/">
                <div className="div-logo">kath.dev</div>
                {/*<div className="nav-logo"></div> */}
            </Link>
            <div className="nav-links main-nav-links">
                <NavLink style={navStyle} to='/portfolio' activeClassName="selected-nav-link">
                    <h2 className="nav-link">Portfolio</h2>
                </NavLink>
                <NavLink style={navStyle} to='/about' activeClassName="selected-nav-link">
                    <h2 className="nav-link">About</h2>
                </NavLink>
                <NavLink style={navStyle} to='/contact' activeClassName="selected-nav-link">
                    <h2 className="nav-link">Contact</h2>
                </NavLink>
            </div>
            <div className={menuState}>
                <NavLink style={navStyle} to='/portfolio' activeClassName="selected-nav-link">
                    <h2 className="nav-link">Portfolio</h2>
                </NavLink>
                <NavLink style={navStyle} to='/about' activeClassName="selected-nav-link">
                    <h2 className="nav-link">About</h2>
                </NavLink>
                <NavLink style={navStyle} to='/contact' activeClassName="selected-nav-link">
                    <h2 className="nav-link">Contact</h2>
                </NavLink>
            </div>
            <div className="hamburger-icon" onClick={toggleBurger}>
                <div className="hamburger-layer"></div>
                <div className="hamburger-layer"></div>
                <div className="hamburger-layer"></div>
            </div>
        </nav>
    )
};

export default Nav