import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import App from "./App";
import Nav from "./Components/Nav";
import Portfolio from "./Components/Portfolio";
import About from "./Components/About";
import Contact from "./Components/Contact";
import Footer from './Components/Footer';



const Routes = () => {
    return (
        <BrowserRouter>
            <Nav />
            <div id="page-container">
                <Switch>
                        <Route exact path="/" component={App} />
                        <Route exact path="/portfolio" component={Portfolio} />
                        <Route exact path="/about" component={About} />
                        <Route exact path="/contact" component={Contact} />
                    
                </Switch>
            </div>
            <Footer/>
        </BrowserRouter>
    );
};

export default Routes;