import React from "react";
import { Link } from 'react-router-dom';
import PortfolioFeatured from "./PortfolioFeatured";

const Home = () => {

    const navStyle = {
        textDecoration: 'none',
        color: 'rgb(30, 30, 30)'
    };

    return (
        <div className="page-container">
            <div className="dark-container home-container">
                <div className="col-text-wrapper">
                    <h1>Hi 👋 I'm Kath</h1>
                    <h1>I'm a web developer</h1>
                    <h1>based in London, UK</h1>
                </div>
                <div className="profile-pic">
                </div>
            </div>
            <div className="page-banner-header">
                <h3>Recently I've made</h3>
                <div className="arrow-logo"></div>
            </div>
            <div className="home-portfolio">
                <PortfolioFeatured/>
                <h4 className="bottom-page-tagline">View more in my 
                    <Link style={navStyle} to='/portfolio'>
                        <span> </span><span className="bolder underline-link">Portfolio</span>
                    </Link>
                </h4>
            </div>
        </div>
    );
};

export default Home;