import React from "react";

const PortfolioFeatured = () => {
    return (
        <div>
            <div className="featured-portfolio">
                <div>
                    <div className="featured-portfolio-pic-wrapper">
                        <a href="https://the-today-list.web.app/" target="_blank" rel="noreferrer">
                            <div className="zoom portfolio-pic featured-portfolio-pic"></div>
                        </a>
                    </div>    
                </div>
                <div className="portfolio-text featured-portfolio-text">
                    <a href="https://the-today-list.web.app/" target="_blank" rel="noreferrer" className="underline-link">
                        <h4 className="portfolio-heading">The Today List</h4>
                    </a>
                    <h5 className="featured-portfolio-subheading">A project &#38; task management app which enables the user to focus on their most important objectives of the day.</h5>
                    <h6 className="list-header">Built with:</h6>    
                        <div className="portfolio-list">
                            <div className="portfolio-icon html-icon"></div>
                            <div className="portfolio-icon css-icon"></div>
                            <div className="portfolio-icon javascript-icon"></div>
                            <div className="portfolio-icon webpack-icon"></div>
                            <div className="portfolio-icon firebase-icon"></div>
                            <a href="https://github.com/kath-ldn/the-today-list" target="_blank"  rel="noreferrer">
                                <div className="portfolio-icon github-icon">
                                    <div className="click-icon"></div>
                                    <div className="click-label">visit GitHub</div>
                                </div>
                            </a>
                        </div>
                </div>
            </div>
        </div>
    );
};

export default PortfolioFeatured;