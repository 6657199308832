import React, {useEffect} from "react";
import PortfolioFeatured from "./PortfolioFeatured";
import Slideshow from "./Slideshow.js";
import bb1 from '../Assets/screenshots/beauty-box-1.png';
import bb2 from '../Assets/screenshots/beauty-box-2.png';
import bb3 from '../Assets/screenshots/beauty-box-4.png';

const Portfolio = () => {

    //prevents page loading partway down when routed via in-text link
    useEffect(() => {
        window.scrollTo(0, 0);
    })

    return (
        <div className="page-container">
            <div className="light-container home-portfolio">
                <PortfolioFeatured/>
            </div> 
            <div className="page-container portfolio-main">
                <div className="portfolio-items-wrapper">
                    <div className="portfolio-item">
                        <div className="slideshow-portfolio-pic">
                            <Slideshow img1={bb1} img2={bb2} img3={bb3}/>
                        </div>
                        <div className="portfolio-text main-portfolio-text">
                            <a href="https://kath-ldn.github.io/shopping-cart-ReactJS/" target="_blank" rel="noreferrer" className="underline-link">
                                <h4 className="portfolio-heading">ReactJS Shopping Cart</h4>
                            </a>
                            <h5 className="portfolio-subheading">An online store that uses React Router for navigation, and hooks to manage the user's cart.</h5>    
                                <div className="portfolio-list main-portfolio-list">
                                    <div className="portfolio-icon javascript-icon"></div>
                                    <div className="portfolio-icon react-icon"></div>
                                    <div className="portfolio-icon css-icon"></div>
                                    <a href="https://github.com/kath-ldn/shopping-cart-ReactJS" target="_blank" rel="noreferrer">
                                        <div className="portfolio-icon github-icon">
                                            <div className="click-icon"></div>
                                        </div>
                                    </a>
                                </div>
                        </div>
                    </div>
                    <div className="portfolio-item">
                        <iframe className="portfolio-video portfolio-pic main-portfolio-pic" src="https://www.youtube.com/embed/AWSNfmPND8g" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        <div className="portfolio-text main-portfolio-text">
                            <a href="https://github.com/kath-ldn/covid_data_analysis" target="_blank" rel="noreferrer" className="underline-link">
                                <h4 className="portfolio-heading">Covid Data Analysis</h4>
                            </a>
                            <h5 className="portfolio-subheading">A Python program which analyses and displays COVID19 vaccines given each day in the UK.</h5>    
                                <div className="portfolio-list main-portfolio-list">
                                    <div className="portfolio-icon python-icon"></div>
                                    <a href="https://github.com/kath-ldn/covid_data_analysis" target="_blank" rel="noreferrer">
                                        <div className="portfolio-icon github-icon">
                                            <div className="click-icon"></div>
                                        </div>
                                    </a>
                                </div>
                        </div>
                    </div>
                    <div className="portfolio-item">
                        <a href="https://kath-ldn.github.io/restaurant-page/" target="_blank" rel="noreferrer">
                            <div className="portfolio-pic main-portfolio-pic restaurant"></div>
                        </a>
                        <div className="portfolio-text main-portfolio-text">
                            <a href="https://kath-ldn.github.io/restaurant-page/" target="_blank" rel="noreferrer" className="underline-link">
                                <h4 className="portfolio-heading">Sushi Restaurant</h4>
                            </a>
                            <h5 className="portfolio-subheading">A restaurant website, which uses Webpack to dynamically render each page.</h5>    
                                <div className="portfolio-list main-portfolio-list">
                                    <div className="portfolio-icon html-icon"></div>
                                    <div className="portfolio-icon css-icon"></div>
                                    <div className="portfolio-icon javascript-icon"></div>
                                    <div className="portfolio-icon webpack-icon"></div>
                                    <a href="https://github.com/kath-ldn/restaurant-page/" target="_blank" rel="noreferrer">
                                        <div className="portfolio-icon github-icon">
                                            <div className="click-icon"></div>
                                        </div>
                                    </a>
                                </div>
                        </div>
                    </div>
                    <div className="portfolio-item">
                        <a href="https://kath-ldn.github.io/bootstrap-fashion/" target="_blank" rel="noreferrer">
                            <div className="portfolio-pic main-portfolio-pic bootstrapfashion"></div>
                        </a>
                        <div className="portfolio-text main-portfolio-text">
                            <a href="https://kath-ldn.github.io/bootstrap-fashion/" target="_blank" rel="noreferrer" className="underline-link">
                                <h4 className="portfolio-heading">Bootstrap Fashion</h4>
                            </a>
                            <h5 className="portfolio-subheading">A responsive, design-focused webpage, based on that of a global fashion brand.</h5>    
                                <div className="portfolio-list main-portfolio-list">
                                    <div className="portfolio-icon html-icon"></div>
                                    <div className="portfolio-icon css-icon"></div>
                                    <div className="portfolio-icon bootstrap-icon"></div>
                                    <a href="https://github.com/kath-ldn/bootstrap-fashion" target="_blank" rel="noreferrer">
                                        <div className="portfolio-icon github-icon">
                                            <div className="click-icon"></div>
                                        </div>
                                    </a>
                                </div>
                        </div>
                    </div>
                    <div className="portfolio-item">
                        <a href="https://kath-ldn.github.io/weather-app/" target="_blank" rel="noreferrer">
                            <div className="portfolio-pic main-portfolio-pic weather"></div>
                        </a>
                        <div className="portfolio-text main-portfolio-text">
                            <a href="https://kath-ldn.github.io/weather-app/" target="_blank" rel="noreferrer" className="underline-link">
                                <h4 className="portfolio-heading">Weather App</h4>
                            </a>
                            <h5 className="portfolio-subheading">An app which allows the user to see the weather today in any city that they search for.</h5>    
                                <div className="portfolio-list main-portfolio-list">
                                    <div className="portfolio-icon html-icon"></div>
                                    <div className="portfolio-icon css-icon"></div>
                                    <div className="portfolio-icon javascript-icon"></div>
                                    <a href="https://github.com/kath-ldn/weather-app" target="_blank" rel="noreferrer">
                                        <div className="portfolio-icon github-icon">
                                            <div className="click-icon"></div>
                                        </div>
                                    </a>
                                </div>
                        </div>
                    </div>
                    <div className="portfolio-item">
                        <a href="https://kath-ldn.github.io/battleship/" target="_blank" rel="noreferrer">   
                            <div className="portfolio-pic main-portfolio-pic battleship"></div>
                        </a>
                        <div className="portfolio-text main-portfolio-text">
                            <a href="https://kath-ldn.github.io/battleship/" target="_blank" rel="noreferrer" className="underline-link"> 
                                <h4 className="portfolio-heading">Battleship</h4>
                            </a>
                            <h5 className="portfolio-subheading">A minimalist re-imagining of the classic board game, that the user against the computer.</h5>    
                                <div className="portfolio-list main-portfolio-list">
                                    <div className="portfolio-icon html-icon"></div>
                                    <div className="portfolio-icon css-icon"></div>
                                    <div className="portfolio-icon javascript-icon"></div>
                                    <div className="portfolio-icon webpack-icon"></div>
                                    <div className="portfolio-icon jest-icon"></div>
                                    <a href="https://github.com/kath-ldn/battleship" target="_blank" rel="noreferrer">
                                        <div className="portfolio-icon github-icon">
                                            <div className="click-icon"></div>
                                        </div>
                                    </a>
                                </div>
                        </div>
                    </div>
                </div>
                <h4 className="bottom-page-tagline portfolio-tagline">See more of my work on <span> </span>
                    <a href="https://github.com/kath-ldn" target="_blank"  rel="noreferrer" className="bolder underline-link">
                    Github
                    </a>
                </h4> 
            </div>
        </div>
    );
};

export default Portfolio;