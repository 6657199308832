import React from 'react';

const Footer = () => {

    return(
        <footer>
            <div className="socials-links">
                <a href="https://github.com/kath-ldn"><div className="social-img github-img"></div></a>
                <a href="https://uk.linkedin.com/in/katharine-turner"><div className="social-img linkedin-img"></div></a>
                <a href={'mailto:$katharineturner3@gmail.com'}><div className="social-img email-img"></div></a>
            </div>
           <p>Made with 💜 by me, © Kath Turner, 2021</p>
        </footer>
    )
};

export default Footer