import React from "react";

const About = () => {
    return (
        <div className="page-container">
            <div className="dark-container about-container">
                <div className="centered-narrow">
                    <h2>Thanks for visiting! I'm a self-taught web developer,
                        who works with Javascript and ReactJS
                        (which I used to build this website).</h2>
                    <h2>I was drawn to web development because it allows me to get hands-on
                        with creative and analytical problems. Putting it simply - I like
                        to make things that look good and work well. I'm a continuous learner, and I'm always looking to develop my skills. I'm currently learning Python.</h2>
                    <h2>Before web development, I was a management consultant and government advisor. Although very different to what I do now, I learned a lot from these experiences - including working as part of a team,
                        problem-solving and project management.</h2>
                    {/*<h2>I'm currently looking for London-based or remote job opportunities. If you think I could be a great fit for your team - please reach out via <a href={'mailto:$katharineturner3@gmail.com'} className="underline-link light-underline-link">email</a> or <a href="https://uk.linkedin.com/in/katharine-turner" className="underline-link light-underline-link">LinkedIn</a>.</h2>*/}
                </div>
            </div>
            <div className="light-container about-skills-container">
                <div className="page-banner-header">
                    <h3>My skills:</h3>
                    <div className="arrow-logo"></div>
                </div>
                <div className="skills-box">
                    <h5 className="skills-heading">Languages</h5>
                    <div className="skills-icon javascript-icon"></div>
                    <div className="skills-icon html-icon"></div>
                    <div className="skills-icon css-icon"></div>
                    <div className="skills-icon python-icon"></div>
                </div>
                <div className="skills-box">
                    <h5 className="skills-heading">Frameworks</h5>
                    <div className="skills-icon react-icon"></div>
                    <div className="skills-icon bootstrap-icon"></div>
                </div>
                <div className="skills-box">
                    <h5 className="skills-heading">Tools</h5>
                    <div className="skills-icon nodejs-icon"></div>
                    <div className="skills-icon npm-icon"></div>
                    <div className="skills-icon webpack-icon"></div>
                    <div className="skills-icon jest-icon"></div>
                    <div className="skills-icon babel-icon"></div>
                    <div className="skills-icon eslint-icon"></div>
                    <div className="skills-icon prettier-icon"></div>
                </div>
                <div className="skills-box">
                    <h5 className="skills-heading">Version Control</h5>
                    <div className="skills-icon git-icon"></div>
                    <div className="skills-icon github-icon"></div>
                </div>
                <div className="skills-box">
                    <h5 className="skills-heading">Design</h5>
                    <div className="skills-icon figma-icon"></div>
                    <div className="skills-icon photoshop-icon"></div>
                </div>
                <div className="skills-box">
                    <h5 className="skills-heading">Text editors/IDEs</h5>
                    <div className="skills-icon vscode-icon"></div>
                    <div className="skills-icon pycharm-icon"></div>
                </div>
                <div className="skills-box">
                    <h5 className="skills-heading">CMS</h5>
                    <div className="skills-icon wordpress-icon"></div>
                </div>
                <div className="skills-box">
                    <h5 className="skills-heading">BaaS</h5>
                    <div className="skills-icon firebase-icon"></div>
                </div>
            </div>
            <div className="light-container about-personal-wrapper">
                <div className="page-banner-header">
                        <h3>More about me:</h3>
                        <div className="inv-arrow-logo"></div>
                    </div>
                    <div className="about-personal-content">
                        <div className="about-personal-text">
                            <h2>When I'm not at my computer:</h2>
                            <h2 className="indent">🧘‍♀️ I teach and practice yoga</h2>
                            <h2 className="indent">⛰️ I like to spend as much time in nature as possible</h2>
                            <h2 className="indent">🗺️ I enjoy travelling and exploring new places (when we're not in a global pandemic).</h2>
                            <h2>I also have a cat called Buster, who will often be found snoozing in my lap as I code.</h2>
                        </div>
                        <div className="about-pic"></div>
                    </div>
            </div>
        </div>
    );
};

export default About;