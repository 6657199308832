import React from "react";

const Contact = () => {
    return (
        <div className="page-container contact-container">
            {/*To add contact form once resolved back end */}
            <div className="contact-icons">
                <a href="https://uk.linkedin.com/in/katharine-turner-13079484"><div className="contact-icon linkedin-dark"></div><h5 className="underline-link">LinkedIn</h5></a>
                <a href={'mailto:$katharineturner3@gmail.com'}><div className="contact-icon email-dark"></div><h5 className="underline-link">Email</h5></a>
                <a href="https://twitter.com/kath_ldn"><div className="contact-icon twitter-dark"></div><h5 className="underline-link">Twitter</h5></a>
                <a href="https://github.com/kath-ldn?tab=repositories"><div className="contact-icon github-dark"></div><h5 className="underline-link">GitHub</h5></a>
            </div>
        </div>
    );
};

export default Contact;